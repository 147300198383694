var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [!_vm.isInvoiceAvailable ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih No.Pengadaan")]), _c('v-select', {
    attrs: {
      "options": _vm.pengadaans,
      "label": "text"
    },
    model: {
      value: _vm.selectedInvoice,
      callback: function callback($$v) {
        _vm.selectedInvoice = $$v;
      },
      expression: "selectedInvoice"
    }
  })], 1) : _c('p', {
    staticClass: "mb-0"
  }, [_c('strong', [_vm._v("ID " + _vm._s(_vm.pengadaan.id) + " No. Pengadaan: " + _vm._s(_vm.pengadaan.no))])])], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "d-flex justify-content-between"
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$router.push("/asset-penyimpanan/add-empty");
      }
    }
  }, [_c('span', {
    staticClass: "align-middle"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Tambah Penyimpanan Kosong")], 1)])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-card', [_c('b-table', {
    attrs: {
      "responsive": "",
      "striped": "",
      "bordered": "",
      "fields": _vm.fields,
      "items": _vm.assets
    },
    scopedSlots: _vm._u([{
      key: "cell(action)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-form-radio', {
          attrs: {
            "value": item,
            "name": "asset"
          },
          model: {
            value: _vm.selectedBarang,
            callback: function callback($$v) {
              _vm.selectedBarang = $$v;
            },
            expression: "selectedBarang"
          }
        })];
      }
    }, {
      key: "cell(nama)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.asset ? item.asset.nama : "-") + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.asset && item.asset.satuan ? item.asset.satuan.satuan : "-") + " ")];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-card', [_c('form', [!_vm.isInvoiceAvailable ? _c('b-form-group', {
    attrs: {
      "hidden": ""
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Pengadaan")]), _c('v-select', {
    attrs: {
      "options": _vm.invoiceList,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.pengadaan_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pengadaan_id", $$v);
      },
      expression: "form.pengadaan_id"
    }
  })], 1) : _vm._e(), _vm.myGudang ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Gudang")]), _c('b-form-input', {
    attrs: {
      "disabled": "",
      "value": _vm.myGudang.nama_gudang
    }
  })], 1) : _vm._e(), _c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "blok",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Lokasi Aset")]), _c('v-select', {
          attrs: {
            "options": _vm.bloks,
            "required": !_vm.form.blok_id,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.blok_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "blok_id", $$v);
            },
            expression: "form.blok_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nilai"
    }
  }, [_vm._v("Jumlah")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "id": "nilai",
      "disabled": "",
      "placeholder": "nilai"
    },
    model: {
      value: _vm.form.jumlah,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "jumlah", $$v);
      },
      expression: "form.jumlah"
    }
  })], 1)], 1)], 1), _c('b-form-group', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addBarang($event);
      }
    }
  }, [_vm._v("Tambah")])], 1)], 1)])], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-table', {
    attrs: {
      "fields": _vm.fieldsAddedItems,
      "bordered": "",
      "striped": "",
      "responsive": "",
      "items": _vm.addedItems
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(lokasi)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.lokasi) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('b-link', {
          staticClass: "text-danger",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addedItems.splice(row.index, 1);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "24",
            "icon": "XCircleIcon"
          }
        })], 1)];
      }
    }])
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }